@font-face {
    font-family: 'JetBrains Mono';
    src: url(./fonts/JetBrainsMono-Light.woff2) format('woff');
    font-weight: 300;
}
@font-face {
    font-family: 'JetBrains Mono';
    src: url(./fonts/JetBrainsMono-Regular.woff2) format('woff');
    font-weight: 400;
}
@font-face {
    font-family: 'JetBrains Mono';
    src: url(./fonts/JetBrainsMono-Medium.woff2) format('woff');
    font-weight: 500;
}
@font-face {
    font-family: 'JetBrains Mono';
    src: url(./fonts/JetBrainsMono-Bold.woff2) format('woff');
    font-weight: 700;
}

html, body {
    margin: 0;
    padding: 0;
}

body {
    background: radial-gradient(circle, rgba(250,250,250,1) 40%, rgba(222,222,222,1) 100%); 
}